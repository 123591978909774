<template>
  <div
    id="video-app"
    class="bg-video-app"
  >
    <div class="d-flex align-center justify-space-around logo-video-app">
      <div class="d-flex align-center justify-center">
        <v-img
          :src="appLogo"
          max-height="40px"
          max-width="40px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
        <span
          class="font-weight-bold text-lg"
          style="color: black !important; font-family: 'Orbitron';"
        >
          {{ appName }}
        </span>
      </div>
      <span
        class="font-weight-bold text-sm"
        style="color: black !important;cursor: pointer;"
        @click="downloadApp"
      >
        {{ t('videos.download_app') }}
      </span>
    </div>
    <div class="header-video-app d-flex justify-center">
      <div class="d-flex align-center">
        <v-avatar
          :color="videoData.share_user_avatar ? '' : 'secondary'"
          :class="videoData.share_user_avatar ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
          size="32"
        >
          <v-img
            :src="videoData.share_user_avatar ? videoData.share_user_avatar : require(`@/assets/images/avatars/user-hp.png`)"
          ></v-img>
        </v-avatar>
        <v-btn
          class="text-sm pa-0 ma-0"
          style="text-transform: inherit;"
          text
          @click="downloadApp"
        >
          <span class="white--text text-sm">
            {{ videoData.share_user }} {{ t('videos.using') }} <strong>{{ t('videos.get_app') }}</strong>
          </span>
        </v-btn>
      </div>
    </div>
    <div
      class="auth-wrapper auth-v1"
    >
      <div
        class="auth-inner"
        style="width:50rem;"
      >
        <v-row class="d-flex justify-center mb-16">
          <v-col
            cols="12"
            class="align-self-start pa-0"
          >
            <v-card
              color="#ffff"
              class="rounded-lg"
              elevation="15"
            >
              <v-card-text class="pa-3">
                <div>
                  <div
                    class="ms-0 width-full"
                  >
                    <v-row class="pa-1">
                      <v-col
                        cols="12"
                        class="mb-1 pa-1 d-flex justify-space-between align-center"
                      >
                        <div class="d-flex align-center">
                          <v-avatar
                            :color="videoData.share_user_avatar ? '' : 'secondary'"
                            :class="videoData.share_user_avatar ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
                            size="40"
                          >
                            <v-img
                              v-if="videoData.share_user_avatar"
                              :src="videoData.share_user_avatar"
                            ></v-img>
                            <span
                              v-else
                              class="
                              font-weight-medium"
                            >
                              {{ videoData.share_user }}
                            </span>
                          </v-avatar>
                          <v-col>
                            <v-row>
                              <span class="text-sm font-weight-bold black--text">{{ videoData.share_user }}</span>
                            </v-row>
                            <v-row>
                              <span class="text-xs">{{ videoData.facility_name }}</span>
                            </v-row>
                          </v-col>
                        </div>
                        <div>
                          <span class="text-sm">{{ videoData.video_type === 'H' ? videoData.video_date : videoData.video_time }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
              <div v-show="videoData.cam_coords.length">
                <video
                  id="player"
                  :class="isLightMode ? 'player video-js vjs-fluid vjs-layout-large' :'player video-js vjs-fluid vjs-layout-large'"
                  crossorigin="anonymous"
                  preload
                  controls
                  muted="muted"
                  autoplay
                  playsinline
                >

                </video>
              </div>
              <div v-show="!videoData.cam_coords.length">
                <video-player
                  v-if="videoData.video_type === 'H'"
                  id="refVideoPlayer"
                  ref="refVideoPlayer"
                  class="video-player-box"
                  :options="playerOptions"
                  :playsinline="true"
                  custom-event-name="customstatechangedeventname"
                ></video-player>
                <!--
                @timeupdate="onPlayerTimeupdate($event)"
                @canplaythrough="onPlayerCanplaythrough($event)"
                @ready="playerReadied"
                @loadeddata="onPlayerLoadeddata($event)"
                @loadedmetadata="onPlayerLoadedmetadata($event)"
              -->
                <v-img
                  v-else
                  class="img-h"
                  width="100%"
                  :src="videoData.facility_logo"
                ></v-img>
              </div>

              <v-card-actions
                v-if="videoData.video_type !== 'V'"
                class="dense mt-1"
              >
                <v-row class="pt-1">
                  <v-col
                    cols="12"
                    class="d-flex justify-end  pl-1"
                  >
                    <div
                      class="d-flex align-center flex-column"
                      style="cursor: pointer;"
                      @click="shareURL(videoData.highlight_name, `${videoData.highlight_name}\n${videoData.field_name} at minute ${videoData.video_time}`, hostLocation)"
                    >
                      <svg
                        height="20pt"
                        width="20pt"
                        viewBox="0 0 512 512"
                      >
                        <g
                          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                          :fill="'#000'"
                          stroke="none"
                        >
                          <path
                            d="M4499 4929 c-20 -4 -60 -18 -90 -30 -30 -11 -949 -300 -2044 -640 -1094 -340 -2007 -626 -2027 -635 -134 -60 -192 -230 -125 -366 16 -32 44 -70 63 -85 18 -16 454 -246 968 -513 l934 -485 488 -938 c338 -649 499 -949 524 -974 68 -70 169 -96 262 -69 70 20 130 64 161 118 14 24 298 921 632 1993 334 1073 625 2006 648 2075 49 150 54 230 18 322 -63 162 -251 266 -412 227z m-431 -569 c2 -22 -129 -157 -891 -918 -492 -490 -902 -892 -911 -892 -9 0 -352 174 -761 387 -648 337 -745 390 -745 410 0 12 6 25 13 30 30 19 3235 1011 3262 1010 24 -2 31 -7 33 -27z m322 -305 c10 -12 -78 -302 -465 -1547 -263 -843 -493 -1581 -512 -1640 -31 -99 -36 -108 -58 -108 -15 0 -30 9 -39 23 -32 51 -766 1470 -766 1480 0 19 1793 1807 1811 1807 9 0 22 -7 29 -15z"
                          />
                        </g>
                      </svg>
                      <span class="text-xs text--black">  {{ t('highlight_view.share') }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, computed, onMounted } from '@vue/composition-api'
import { useRouter, downloadAppByOS } from '@core/utils'
import 'video.js/dist/video-js.css'
import { mdiPlus, mdiRefresh, mdiLock, mdiDotsVertical, mdiStar } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { getVideoPlaybackType, logUserActivity } from '@api'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { loadLanguageAsync } from '@/plugins/i18n'
import useShare from '@core/utils/useShare'

export default {
  setup() {
    const { shareURL } = useShare()
    const hostLocation = window.location.href
    const videoData = ref({
      end_playback_time: null,
      facility_logo: null,
      facility_name: null,
      field_name: null,
      highlight_name: null,
      highlight_owner: null,
      highlight_owner_avatar: null,
      share_user: null,
      share_user_avatar: null,
      start_playback_time: null,
      thumbnails: null,
      video_date: null,
      video_id: null,
      video_link: null,
      video_long: null,
      video_thumbnail: null,
      video_time: null,
      video_type: null,
      cam_coords: [],
    })
    const refUsername = ref(null)
    const refVideo = ref(null)
    const players = ref({})
    const logo = ref(require('@/assets/images/icons/logo.png'))
    const source = ref('https://static.smartisanos.cn/common/video/smartisan-tnt-jianguo.mp4')
    const initdone = ref(false)
    const cover = ref(
      'https://static.smartisanos.cn/pr/assets/images/smartisan-tnt-jianguo.190162f215c4f5f2aaabf38e5782b3af.jpg',
    )

    const playerOptions = ref({
      controls: false,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-api-stage.s3.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
      fluid: true,
      aspectRatio: '16:9',
    })
    const USER_ACT_VIEW_HIGHLIGHT_WEB = 'Open Highlight Web'
    const { t } = useUtils()
    const { route, router } = useRouter()
    const refVideoPlayer = ref(null)
    const player = computed(() => refVideoPlayer.value.player)
    let vmplayer = null
    let vmIntervalT = null
    const onPlayerTimeupdate = auxPlayer => {
      if (
        // eslint-disable-next-line operator-linebreak
        videoData.value &&
        // eslint-disable-next-line operator-linebreak
        (auxPlayer.currentTime() >= videoData.value.end_playback_time ||
          auxPlayer.currentTime() < videoData.value.start_playback_time)
      ) {
        initdone.value = false
        auxPlayer.currentTime(videoData.value.start_playback_time)
      }
    }
    const onPlayerCanplaythrough = auxPlayer => {
      if (!initdone.value && videoData.value && videoData.value.video_type === 'H') {
        auxPlayer.currentTime(videoData.value.start_playback_time)
        initdone.value = true
      }
    }

    const onPlayerCanplay = auxPlayer => {
      auxPlayer.currentTime(videoData.value.start_playback_time)
    }

    const playerReadied = auxPlayer => {
      if (!initdone.value && videoData.value && videoData.value.video_type === 'H') {
        auxPlayer.currentTime(videoData.value.start_playback_time)
        initdone.value = true
      }
    }

    const onPlayerLoadeddata = auxPlayer => {
      auxPlayer.currentTime(videoData.value.start_playback_time)
    }

    const onPlayerLoadedmetadata = auxPlayer => {
      if (!initdone.value && videoData.value && videoData.value.video_type === 'H') {
        auxPlayer.currentTime(videoData.value.start_playback_time)
        initdone.value = true
      }
    }

    function arrayToObject(array) {
      const obj = {}
      array.forEach(item => {
        const key = Object.keys(item)[0]
        obj[key] = item[key]
      })

      return obj
    }

    function playHighlightLoop(startTime, endTime, currentTime, elplayer, loop = false, camCoords = []) {
      const frameRate = 20
      if (currentTime >= endTime && loop) {
        const objCameraPositions = arrayToObject(camCoords)

        clearInterval(vmIntervalT)
        elplayer.currentTime(startTime)
        vmIntervalT = setInterval(() => {
          const currentFrame = Math.floor(elplayer.currentTime() * frameRate)

          if (objCameraPositions[`${currentFrame}`]) {
            elplayer.vr().camera.position.set(objCameraPositions[`${currentFrame}`].x, objCameraPositions[`${currentFrame}`].y, objCameraPositions[`${currentFrame}`].z)
          }
        }, 60 / frameRate)
      }
    }

    function setManualViewerVideo(videoRNData, enableMotion = true) {
      try {
        vmplayer = window.videojs('player', {
          disablePictureInPicture: true,
          aspectRatio: '16:9',
          controlBar: {
            playToggle: false,
            captionsButton: false,
            chaptersButton: false,
            subtitlesButton: false,
            remainingTimeDisplay: false,
            progressControl: {
              seekBar: true,
            },
            fullscreenToggle: false,
            playbackRateMenuButton: false,
          },
          userActions: {
            doubleClick: false,
          },
        })
        vmplayer.src({ type: 'video/mp4', src: videoRNData.value.video_stitched_link })
        // eslint-disable-next-line no-use-before-define
        vmplayer.nuevo({
          shareMenu: false,
          zoomMenu: false,
          contextMenu: false,
          relatedMenu: false,
          rateMenu: false,
          settingsButton: false,
        })

        if (!vmplayer.mediainfo) {
          vmplayer.mediainfo = {}
        }

        if (!vmplayer.mediainfo.projection) {
          vmplayer.mediainfo.projection = '360'
        }

        vmplayer.vr({ projection: '360', motionControls: true })

        vmplayer.load()
        window.addEventListener('deviceorientation', event => {
          // Rotación alrededor del eje Z (0 a 360 grados)
          const { beta } = event // Inclinación frontal/trasera del dispositivo (-180 a 180 grados)
          if (beta > 80) {
            vmplayer.vr().camera.position.set(vmplayer.vr().camera.position.x, 0, vmplayer.vr().camera.position.z)
            vmplayer.vr().controls3d.update()
          }
        })
        vmplayer.on('loadedmetadata', () => {
          setTimeout(() => {
            player.vr().camera.position.set(0, 0, 0)
            const maxPolarAngle = 59 * (Math.PI / 180)
            const minPolarAngle = 90 * (Math.PI / 180)

            vmplayer.vr().camera.position.set(0, 0, 0)

            vmplayer.vr().controls3d.orbit.maxPolarAngle = maxPolarAngle
            vmplayer.vr().controls3d.orbit.minPolarAngle = minPolarAngle
            vmplayer.vr().controls3d.orbit.maxAzimuthAngle = Math.PI / 2.6
            vmplayer.vr().controls3d.orbit.minAzimuthAngle = -Math.PI / 2.6
            vmplayer.vr().controls3d.update()
          }, 1000)

          if (vmplayer.vr() && vmplayer.vr().camera) {
            vmplayer.vr().camera.zoom = 0.8
            vmplayer.vr().camera.fov = 50
            vmplayer.vr().camera.updateProjectionMatrix()
            if (!enableMotion) {
              console.log('entra', enableMotion)
              setTimeout(() => {
                vmplayer.vr().controls3d.orientation.disableDevice()
              }, 1000)
            }
          }
          vmplayer.currentTime(videoRNData.value.start_playback_time)
          const objCameraPositions = arrayToObject(videoRNData.value.cam_coords)
          vmIntervalT = setInterval(() => {
            const currentFrame = Math.floor(vmplayer.currentTime() * 20)
            if (objCameraPositions[`${currentFrame}`]) {
              vmplayer.vr().camera.position.set(objCameraPositions[`${currentFrame}`].x, objCameraPositions[`${currentFrame}`].y, objCameraPositions[`${currentFrame}`].z)
            }
          }, 60 / 20)
        })

        vmplayer.on('timeupdate', () => {
          const current = vmplayer.currentTime()
          playHighlightLoop(
            videoRNData.value.start_playback_time,
            videoRNData.value.end_playback_time,
            current,
            vmplayer,
            true,
            videoRNData.value.cam_coords,
          )
        })

        vmplayer.on('error', () => {
          console.log('error', vmplayer.error())
        })
        window.addEventListener('resize', () => {
          vmplayer.vr().camera.aspect = 1.77

          vmplayer.vr().camera.updateProjectionMatrix()
          vmplayer.vr().controls3d.handleResize()
        })

        // player.vr().controls3d.update()
      } catch (error) {
        console.log('🚀 ~ setManualViewerVideo ~ error:', error)
      }
    }

    onMounted(async () => {
      // loadLanguageAsync(navigator.language.includes('en') ? 'en' : 'es' || 'en')
      loadLanguageAsync('en')
      refVideo.value = route.value.params.playback
      refUsername.value = route.value.params.username

      const response = await getVideoPlaybackType(refVideo.value, 'H', refUsername.value)
      if (response.ok) {
        videoData.value = response.data
        if (videoData.value.cam_coords.length) {
          console.log('manual viewer')
          setManualViewerVideo(videoData, false)
          document.getElementById('player').style.pointerEvents = 'none'
        } else {
          playerOptions.value.sources.push({
            type: 'video/mp4',
            src: videoData.value.video_link,
          })

          logUserActivity({
            user_id: videoData.value.highlight_owner,
            video_id: null,
            highlight_id: videoData.value.highlight_id,
            action: USER_ACT_VIEW_HIGHLIGHT_WEB,
          })
        }
      } else {
        router.push({
          path: '/video-not-available',
        })
      }
    })

    const downloadApp = () => {
      // if (isAndroid()) window.open('https://play.google.com/store/apps/details?id=com.fanaty', '_blank')
      // if (isIOS()) window.open('https://apps.apple.com/us/app/fanaty/id1461051554', '_blank')
      // if (getOS() === 'Other') window.open('https://fanaty.com/download', '_blank')
      downloadAppByOS(
        'https://play.google.com/store/apps/details?id=com.fanaty',
        'https://apps.apple.com/us/app/fanaty/id1461051554',
        'https://fanaty.com/download',
      )
    }

    return {
      videoData,
      refUsername,
      refVideo,
      players,
      logo,
      initdone,
      source,
      cover,
      USER_ACT_VIEW_HIGHLIGHT_WEB,
      hostLocation,
      onPlayerTimeupdate,
      onPlayerCanplaythrough,
      onPlayerCanplay,
      onPlayerLoadeddata,
      onPlayerLoadedmetadata,
      playerReadied,
      player,
      playerOptions,
      downloadApp,
      avatarText,
      shareURL,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // i18n
      t,

      icons: {
        mdiPlus,
        mdiRefresh,
        mdiLock,
        mdiDotsVertical,
        mdiStar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style scope>
.video-player-box .video-js,
.img-h {
  width: 100% !important;
  height: 56vh !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 50vh !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }
}

#video-app {
  font-family: 'Open Sans' !important;
}

.bg-video-app {
  background-color: #F2F2F2;
}

.header-video-app {
  background-color: #3F3F3F;
  min-height: 6vh;
}

.logo-video-app {
  background-color: #ffff;
  min-height: 10vh;
}

.btn-video-app {
  min-height: 12vh;
}

.auth-wrapper {
  min-height: calc(var(--vh, 1vh) * 84);
}

.img-h .v-responsive__content {
  width: 100% !important;
}

.img-h .v-responsive__sizer {
  padding: 0 !important;
}
.vjs-control-bar {
  display: none !important;
}

</style>
